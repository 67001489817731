<template>
  <el-scrollbar
    :native="false"
    style="margin-top: 34px; height:calc(100vh - 60px);"
  >
    <div style="max-height: 100vh;" @click="handleClear">
      <el-row type="flex">
        <el-col :span="12" :offset="1">
          <PageHeader :title="dsTitle"></PageHeader>
        </el-col>
      </el-row>
      <div class="contentListContainer" style="padding: 0 45px 0 70px">
        <el-row type="flex">
          <el-col>
            <el-dialog
              title="Filter Data"
              :visible.sync="dialogVisible"
              width="50%"
              destroy-on-close
              append-to-body
            >
              <div class="contentListContainer">
                <!-- propagate input, for your component to work with v-model -->
                <query-condition-builder
                  :fields="columnsForQueryBuilder"
                  :value="eb"
                  :fieldTypes="fieldTypes"
                  :operators="operators"
                  v-loading="requestingDataStoreData"
                  @previewDataStore="previewData"
                >
                  <!-- @previewDataStore="previewResponse" -->
                </query-condition-builder>
              </div>
            </el-dialog>
            <el-dialog
              title="Filter Data for Export"
              :visible.sync="configureExportDialogVisible"
              width="50%"
              destroy-on-close
              append-to-body
              v-loading="exportsLoading"
            >
              <div class="contentListContainer">
                <!-- propagate input, for your component to work with v-model -->
                <query-condition-builder
                  ref="query-condition-builder"
                  :fields="columnsForQueryBuilder"
                  :value="eb"
                  :fieldTypes="fieldTypes"
                  :operators="operators"
                  v-loading="requestingDataStoreData"
                  @previewDataStore="previewData"
                  :showAddGroup="false"
                  ><!-- @previewDataStore="previewResponse" -->
                </query-condition-builder>
                <el-row type="flex">
                  <el-col :span="7" :offset="0">
                    <el-form
                      ref="exportForm"
                      :rules="rules"
                      :model="exportFormData"
                    >
                      <el-form-item label="Row Count" prop="row_count">
                        <el-input v-model="exportFormData.row_count"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="6" :offset="1">
                    <el-button style="margin-top: 28px;" @click="requestExport"
                      >Request Data Export</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </el-dialog>
            <div v-loading="loadingCollectionData">
              <preview-table
                :tableData="dsData"
                :tableColumns="selectedDsCollectionStructure"
                :defaultColumns="defaultColumnNames"
                :loading-table="loadingCollectionData"
                :exportData="exportData"
                @save-data-event="handleSaveDataEvent"
                @insert-data-event="handleInsertDataEvent"
                @delete-data-event="handleDeleteDataEvent"
                @checkbox-select-event="handleCheckBoxSelectEvent"
                @update-sort-event="updateSortEvent"
                @refresh-data-event="refreshDsData(true)"
                @show-query-condition="dialogVisible = true"
                @reset-configure-export-condition="
                  resetConfigureExportCondition = true
                "
                @show-configure-export-data="showConfigureExportDialog"
                tableSize="large"
                allow-export
                :allow-edit="can('build.content.data-stores.write')"
                :show-pagination="true"
                :allowConfigureExport="true"
              >
                <el-form class="form-container" slot="paginationToolbar">
                  <PaginationToolbar
                    :content-api="getDataStoresCollection"
                    :show-search="false"
                    :key="`view-data-${datastore.data_store_id}`"
                    @success="handleFetchSuccess"
                    list-changed-event-name="documents-changed"
                  >
                  </PaginationToolbar>
                </el-form>
              </preview-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import PaginationToolbar from "@/components/PaginationToolbar";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";

import PreviewTable from "@/components/queryConditionBuilder/PreviewTable";
import QueryConditionBuilder from "@/components/queryConditionBuilder/QueryConditionBuilder";
import _ from "lodash";
import DataStore from "@/mixins/DataStore";
import PageHeader from "@/components/PageHeader";

export default {
  mixins: [DataStore],
  name: "viewData",
  components: {
    PaginationToolbar,
    PreviewTable,
    QueryConditionBuilder,
    PageHeader
  },
  props: {
    datastore: {
      type: Object,
      required: true
    }
  },
  data() {
    const validateRowCount = (rule, value, callback) => {
      if (_.isEmpty(this.exportFormData.row_count)) {
        callback("Row count is required.");
      }
      if (isNaN(this.exportFormData.row_count)) {
        callback("Row count should be a number.");
      }
      if (
        this.exportFormData.row_count < 0 ||
        this.exportFormData.row_count > 500000
      ) {
        callback("Row count should be between 1 - 500000.");
      } else {
        callback();
      }
    };
    return {
      pageSize: 24,
      exportData: [],
      viewData: false,
      requestingDataStoreData: false,
      selectedRecords: [],
      sortByColumns: ["_id"],
      configureExportDialogVisible: false,
      resetConfigureExportCondition: false,
      exportFormData: {
        row_count: null
      },
      rules: {
        row_count: [
          {
            required: true,
            trigger: "blur",
            validator: validateRowCount
          }
        ]
      }
    };
  },
  created() {
    this.buildRequestData();
    this.resetConditionValue();
    this.updateColumnList();
    EventBus.$on("page-size-changed", pageSize => {
      this.pageSize = pageSize;
    });
  },
  methods: {
    ...mapActions("datastorecollection", {
      insertDataStoreDocument: "insertDataStoreDocument",
      bulkDeleteDocuments: "bulkDeleteDataStoreDocuments",
      bulkUpdateDocuments: "bulkUpdateDataStoreDocuments"
    }),

    ...mapActions("exports", {
      requestDataExport: "requestDataExport"
    }),

    capitalize(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    previewData() {
      let requestData = {};
      requestData["where"] = this.eb.root.toJSON();
      requestData["row_limit"] = this.rowLimit;
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["order_by"] = this.orderBy;
      this.setRequestData(requestData);
      EventBus.$emit("documents-changed", this.dsData, {
        page: 1
      });
      this.configureExportDialogVisible = false;
    },
    refreshDsData(removeConditions = false) {
      let additionalInfo = {};
      let requestData = {};
      if (removeConditions) {
        this.resetConditionValue();
        additionalInfo.page = 1;
      }
      requestData["where"] = this.eb.root.toJSON();
      requestData["row_limit"] = this.rowLimit;
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["order_by"] = this.orderBy;
      this.setRequestData(requestData);

      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, additionalInfo);
      });
    },
    updateSortEvent(orderBy) {
      this.orderBy = orderBy;
      this.previewData();
    },
    buildRequestData() {
      let requestData = {};
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["where"] = this.eb.root.toJSON();
      requestData["row_limit"] = this.rowLimit;
      requestData["order_by"] = this.orderBy;
      this.setRequestData(requestData);
    },
    //  Update the column values and fields list based on selected datastore
    updateColumnList() {
      let vm = this;
      let collectionStructure = this.datastore.collection_structure;
      // // // reset existing query conditions
      // // this.resetConditionValue();

      this.reInitializeDataStoreColumns();

      _.forEach(collectionStructure, function(value) {
        // push the query condition fields value
        let fieldType = vm.fieldType(value.type);
        vm.pushNewColumnToCollectionStructure({
          name: value.col_name,
          label: value.label ? value.label : value.col_name,
          secure: !!value.secure,
          type: fieldType,
          visible: true,
          choices: null
        });
      });
    },
    handleClear() {
      this.$refs.datastoresDataTable &&
        this.$refs.datastoresDataTable.setCurrentRow();
    },
    handleDeleteDataEvent(deletedRows) {
      let requestData = {};
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["data"] = deletedRows;
      this.bulkDeleteDocuments(requestData)
        .then(() => {
          this.$message({
            // eslint-disable-next-line
            message: __("data-store documents deletion requested."),
            type: "success"
          });
        })
        .catch(err => {
          this.$message({
            message: err.message,
            type: "error"
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.refreshDsData();
          }, 200);
        });
    },
    handleSaveDataEvent(updateRecords) {
      updateRecords = this.parseData(updateRecords);
      let requestData = {};
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["data"] = updateRecords;
      this.bulkUpdateDocuments(requestData)
        .then(() => {
          this.$message({
            message: __("data-store documents update requested."), //data,
            type: "success"
          });
        })
        .catch(err => {
          this.$message({
            message: err.msg,
            type: "error"
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.refreshDsData();
          }, 200);
        });
    },
    handleInsertDataEvent(insertRecord) {
      let requestData = {};
      requestData["data_store_id"] = this.datastore.data_store_id;
      requestData["data"] = [insertRecord];
      this.insertDataStoreDocument(requestData)
        .then(() => {
          this.$message({
            message: __("Inserted new record"),
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            message: __("Insert failed"),
            type: "error"
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.refreshDsData();
          }, 200);
        });
    },
    handleCheckBoxSelectEvent(records) {
      this.selectedRecords = records;
    },

    showConfigureExportDialog() {
      this.dialogVisible = false;
      if (this.resetConfigureExportCondition) {
        this.resetConditionValue();
      }
      this.resetConfigureExportCondition = false;
      this.configureExportDialogVisible = true;
    },

    requestExport() {
      this.$refs.exportForm.validate(valid => {
        if (valid) {
          let requestData = {};
          requestData["data_store_id"] = this.datastore.data_store_id;
          requestData["query_condition"] = this.eb.root.toJSON();
          requestData["row_count"] = this.exportFormData.row_count;
          requestData["ac_id"] = this.selectedAccountId;
          requestData["sp_id"] = this.selectedServiceProviderId;
          this.requestDataExport(requestData)
            .then(data => {
              let message = _.isEmpty(data.data.message)
                ? "Data export requested."
                : data.data.message;
              if (data.data.message == "CSV file successfully downloaded") {
                this.exportData = data.data.data;
              }
              this.$message({
                type: "success",
                message: message
              });
              this.configureExportDialogVisible = false;
            })
            .catch(err => {
              console.log(err);
              this.$message({
                type: "error",
                message: err.message
              });
              this.configureExportDialogVisible = false;
            });
        }
      });
    }
  },
  computed: {
    ...mapState("exports", {
      exportsLoading: state => state.loading
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    dsTitle() {
      return (
        __("Datastore") +
        ": " +
        __(":display_name data", { display_name: this.datastore.display_name })
      );
    }
  },
  watch: {
    eb: {
      deep: true,
      handler() {
        this.buildRequestData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.advancedBtn {
  margin-left: 20px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
</style>
